<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/service-top-bg.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="2"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="2"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- tab 切换开始 -->
    <div class="tab-content hidden-xs-only">
      <div
        class="tab-item"
        :class="tab == 1 ? 'tab-active' : ''"
        @click="tab = 1"
      >
        {{ $t("message.subNav3_1") }}
      </div>
      <div
        class="tab-item"
        :class="tab == 2 ? 'tab-active' : ''"
        @click="tab = 2"
      >
        {{ $t("message.subNav3_2") }}
      </div>
      <div
        class="tab-item"
        :class="tab == 3 ? 'tab-active' : ''"
        @click="tab = 3"
      >
        {{ $t("message.subNav3_3") }}
      </div>
      <div
        class="tab-item"
        :class="tab == 4 ? 'tab-active' : ''"
        @click="tab = 4"
      >
        {{ $t("message.subNav3_4") }}
      </div>
    </div>
    <div class="container hidden-sm-and-up">
      <el-row :gutter="10">
        <el-col :span="12">
          <div
            class="tab-content-sm-item tab-content-sm-btn"
            :class="tab == 1 ? 'tab-active-sm' : ''"
            @click="tab = 1"
          >
            {{ $t("message.subNav3_1") }}
          </div>
        </el-col>
        <el-col :span="12">
          <div
            class="tab-content-sm-item tab-content-sm-btn"
            :class="tab == 2 ? 'tab-active-sm' : ''"
            @click="tab = 2"
          >
            {{ $t("message.subNav3_2") }}
          </div>
        </el-col>
        <el-col :span="12">
          <div
            class="tab-content-sm-item tab-content-sm-btn"
            :class="tab == 3 ? 'tab-active-sm' : ''"
            @click="tab = 3"
          >
            {{ $t("message.subNav3_3") }}
          </div>
        </el-col>
        <el-col :span="12">
          <div
            class="tab-content-sm-item tab-content-sm-btn"
            :class="tab == 4 ? 'tab-active-sm' : ''"
            @click="tab = 4"
          >
            {{ $t("message.subNav3_4") }}
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- tab 切换结束 -->
    <!-- 展会/活动承接开始 -->
    <div class="activity" v-if="tab == 1">
      <!-- 展会/活动承接内容开始 -->
      <div
        class="activity1 hidden-xs-only"
        v-if="activityList && activityList.length != 0"
      >
        <!-- 内容容器开始 -->
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title">
            <div v-show="language == 'cn'">{{ activityList.name }}</div>
            <div v-show="language == 'en'">{{ activityList.enName }}</div>
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <!-- 单一内容开始 -->
          <div class="activity1-content">
            <!-- 图片内容开始 -->
            <div class="activity-img-content">
              <img :src="activityList.img" alt="" />
            </div>
            <!-- 图片内容结束 -->
            <!-- 文字内容开始 -->
            <div class="activity1-msg-content">
              <!-- 信息开始 -->
              <div class="activity1-content-msg">
                <div v-show="language == 'cn'">{{ activityList.content }}</div>
                <div v-show="language == 'en'">
                  {{ activityList.enContent }}
                </div>
              </div>
              <!-- 信息结束 -->
              <!-- 联系方式开始 -->
              <div class="activity1-phone">
                <div class="activity1-phone-img">
                  <img src="../assets/svg/server-phone-icon.svg" alt="" />
                </div>
                <div class="activity1-phone-label">
                  {{ $t("message.cooperation") }}：
                </div>
                <div class="activity1-phone-msg">027-84696948</div>
              </div>
              <!-- 联系方式结束 -->
            </div>
            <!-- 文字内容结束 -->
          </div>
          <!-- 单一内容结束 -->
        </div>
        <!-- 内容容器结束 -->
      </div>
      <div
        class="smBox hidden-sm-and-up"
        v-if="activityList && activityList.length != 0"
      >
        <div class="container">
          <!-- 文字开始 -->
          <div>
            <div v-show="language == 'cn'">{{ activityList.content }}</div>
            <div v-show="language == 'en'">
              {{ activityList.enContent }}
            </div>
          </div>
          <!-- 文字结束 -->
          <!-- 图片开始 -->
          <div class="smBox-img">
            <img :src="activityList.img" alt="" />
          </div>
          <!-- 图片结束 -->
          <!-- 联系方式开始 -->
          <div class="activity1-phone">
            <div class="activity1-phone-img">
              <img src="../assets/svg/server-phone-icon.svg" alt="" />
            </div>
            <div class="activity1-phone-label">
              {{ $t("message.cooperation") }}：
            </div>
            <div class="activity1-phone-msg">027-84696948</div>
          </div>
          <!-- 联系方式结束 -->
        </div>
      </div>
      <!-- 展会/活动承接内容结束 -->
      <!-- 展会第二部分开始 -->
      <div class="activity2 hidden-xs-only">
        <div class="container">
          <div class="activity2-content flex">
            <!-- 单一内容开始 -->
            <div
              class="activity2-content-item"
              v-if="marketingList && marketingList.length != 0"
            >
              <!-- 标题开始 -->
              <div class="sub-title1">
                <span></span>
                <div v-show="language == 'cn'">{{ marketingList.name }}</div>
                <div v-show="language == 'en'">{{ marketingList.enName }}</div>
              </div>
              <!-- 标题结束 -->
              <!-- 信息开始 -->
              <div class="activity2-content-msg">
                <div v-show="language == 'cn'">{{ marketingList.content }}</div>
                <div v-show="language == 'en'">
                  {{ marketingList.enContent }}
                </div>
              </div>
              <!-- 信息结束 -->
            </div>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <div
              class="activity2-content-item"
              v-if="undertakeList && undertakeList.length != 0"
            >
              <!-- 标题开始 -->
              <div class="sub-title1">
                <span></span>
                <div v-show="language == 'cn'">{{ undertakeList.name }}</div>
                <div v-show="language == 'en'">{{ undertakeList.enName }}</div>
              </div>
              <!-- 标题结束 -->
              <!-- 信息开始 -->
              <div class="activity2-content-msg">
                <div v-show="language == 'cn'">{{ undertakeList.content }}</div>
                <div v-show="language == 'en'">
                  {{ undertakeList.enContent }}
                </div>
              </div>
              <!-- 信息结束 -->
            </div>
            <!-- 单一内容结束 -->
          </div>
        </div>
      </div>
      <div class="container hidden-sm-and-up">
        <div
          class="smBox smBox-bg"
          v-if="marketingList && marketingList.length != 0"
        >
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">{{ marketingList.name }}</div>
            <div v-show="language == 'en'">{{ marketingList.enName }}</div>
          </div>
          <!-- 标题结束 -->
          <!-- 文字开始 -->
          <div>
            <div v-show="language == 'cn'">{{ marketingList.content }}</div>
            <div v-show="language == 'en'">
              {{ marketingList.enContent }}
            </div>
          </div>
          <!-- 文字结束 -->
        </div>
        <div
          class="smBox smBox-bg"
          v-if="undertakeList && undertakeList.length != 0"
        >
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">{{ undertakeList.name }}</div>
            <div v-show="language == 'en'">{{ undertakeList.enName }}</div>
          </div>
          <!-- 标题结束 -->
          <!-- 文字开始 -->
          <div>
            <div v-show="language == 'cn'">{{ undertakeList.content }}</div>
            <div v-show="language == 'en'">
              {{ undertakeList.enContent }}
            </div>
          </div>
          <!-- 文字结束 -->
        </div>
      </div>
      <!-- 展会第二部分结束 -->
      <!-- 承办案例开始 -->
      <div class="activity3">
        <div class="container">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            {{ $t("message.cases") }}
          </div>
          <!-- 标题结束 -->
          <!-- 内容开始 -->
          <div class="activity3-content">
            <div class="hidden-xs-only">
              <my-carousel :number="5"></my-carousel>
            </div>
            <div class="hidden-sm-and-up">
              <my-carousel2 :number="3"></my-carousel2>
            </div>
          </div>
          <!-- 内容结束 -->
        </div>
      </div>
      <!-- 承办案例结束 -->
    </div>
    <!-- 展会/活动承接结束 -->
    <!-- 展会自联办服务开始 -->
    <div class="exhibition" v-if="tab == 2">
      <div class="container">
        <!-- 标题部分开始 -->
        <div class="title">
          {{ $t("message.subNav3_2") }}
          <span class="small"></span>
          <span class="big"></span>
        </div>
        <!-- 标题部分结束 -->
        <!-- 自联办开始 -->
        <div
          class="exhibition1 hidden-xs-only"
          v-if="exhibitionList && exhibitionList.length != 0"
        >
          <!-- 左侧图片开始 -->
          <div class="exhibition1-img-content">
            <img :src="exhibitionList.img" alt="" />
          </div>
          <!-- 左侧图片结束 -->
          <!-- 右侧内容开始 -->
          <div class="exhibition1-msg-content">
            <!-- 信息开始 -->
            <div class="exhibition1-msg-content-msg">
              <div v-show="language == 'cn'">{{ exhibitionList.content }}</div>
              <div v-show="language == 'en'">
                {{ exhibitionList.enContent }}
              </div>
            </div>
            <!-- 信息结束 -->
            <!-- 联系方式开始 -->
            <div class="exhibition1-phone">
              <div class="exhibition1-phone-img">
                <img src="../assets/svg/server-phone-icon.svg" alt="" />
              </div>
              <div class="exhibition1-phone-label">
                {{ $t("message.cooperation") }}：
              </div>
              <div class="exhibition1-phone-msg">{{ exhibitionList.phone }}</div>
            </div>
            <!-- 联系方式结束 -->
          </div>
          <!-- 右侧内容结束 -->
        </div>

        <!-- 自联办结束 -->
      </div>
      <div
        class="hidden-sm-and-up"
        v-if="exhibitionList && exhibitionList.length != 0"
      >
        <!-- 内容容器开始 -->
        <div class="container">
          <div class="smBox">
            <!-- 信息开始 -->
            <div>
              <div v-show="language == 'cn'">
                {{ exhibitionList.content }}
              </div>
              <div v-show="language == 'en'">
                {{ exhibitionList.enContent }}
              </div>
            </div>
            <!-- 信息结束 -->
            <!-- 图片开始 -->
            <div class="smBox-img">
              <img :src="exhibitionList.img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 联系方式开始 -->
            <div class="activity1-phone">
              <div class="activity1-phone-img">
                <img src="../assets/svg/server-phone-icon.svg" alt="" />
              </div>
              <div class="activity1-phone-label">
                {{ $t("message.cooperation") }}：
              </div>
              <div class="activity1-phone-msg">{{ exhibitionList.phone }}</div>
            </div>
            <!-- 联系方式结束 -->
          </div>
        </div>
        <!-- 内容容器结束 -->
      </div>
      <!-- 自联办展品牌项目开始 -->
      <div class="exhibition2">
        <div class="container">
          <div class="exhibition2-content">
            <!-- 标题开始 -->
            <div class="sub-title1">
              <span></span>
              {{ $t("message.zlbProject") }}
            </div>
            <!-- 标题结束 -->
            <!-- 图片切换开始 -->
            <el-carousel
              :interval="4000"
              class="hidden-xs-only"
              type="card"
              height="400px"
              indicator-position="none"
              ref="carousel"
              @change="changeIndex"
              v-if="exhibitionLists && exhibitionLists.length != 0"
            >
              <el-carousel-item
                v-for="(item, index) in exhibitionLists"
                :key="index"
              >
                <img style="width: 100%" :src="item.img" alt="" />
              </el-carousel-item>
            </el-carousel>
            <el-carousel
              :interval="4000"
              class="hidden-sm-and-up"
              type="card"
              height="160px"
              indicator-position="none"
              ref="carousel"
              @change="changeIndex"
              v-if="exhibitionLists && exhibitionLists.length != 0"
            >
              <el-carousel-item
                v-for="(item, index) in exhibitionLists"
                :key="index"
              >
                <img style="width: 100%" :src="item.img" alt="" />
              </el-carousel-item>
            </el-carousel>
            <!-- 图片切换结束 -->
            <!-- 信息开始 -->
            <div
              class="exhibition2-content-msg"
              v-if="exhibitionLists && exhibitionLists.length != 0"
            >
              <div v-show="language == 'cn'">
                {{ exhibitionLists[carouselIndex].content }}
              </div>
              <div v-show="language == 'en'">
                {{ exhibitionLists[carouselIndex].enContent }}
              </div>
            </div>
            <!-- 信息结束 -->
          </div>
        </div>
      </div>
      <!-- 自联办展品牌项目结束 -->
    </div>
    <!-- 展会自联办服务结束 -->
    <!-- 广告服务开始 -->
    <div class="advert" v-if="tab == 3">
      <!-- 广告服务第一部分开始 -->
      <div class="advert1 hidden-xs-only">
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title">
            {{ $t("message.subNav3_3") }}
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <!-- 内容开始 -->
          <div class="advert1-content flex">
            <!-- 单一内容开始 -->
            <div
              class="advert1-item"
              v-if="designList && designList.length != 0"
            >
              <!-- 标题开始 -->
              <div class="advert1-item-title">
                <div v-show="language == 'cn'">{{ designList.name }}</div>
                <div v-show="language == 'en'">{{ designList.enName }}</div>
                <div class="advert1-item-hr"></div>
              </div>
              <!-- 标题开始 -->
              <!-- 文本开始 -->
              <div class="advert1-item-msg">
                <div v-show="language == 'cn'">{{ designList.content }}</div>
                <div v-show="language == 'en'">{{ designList.enContent }}</div>
              </div>
              <!-- 文本结束 -->
            </div>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <div class="advert1-item" v-if="showList && showList.length != 0">
              <!-- 标题开始 -->
              <div class="advert1-item-title">
                <div v-show="language == 'cn'">{{ showList.name }}</div>
                <div v-show="language == 'en'">{{ showList.enName }}</div>
                <div class="advert1-item-hr"></div>
              </div>
              <!-- 标题开始 -->
              <!-- 文本开始 -->
              <div class="advert1-item-msg">
                <div v-show="language == 'cn'">{{ showList.content }}</div>
                <div v-show="language == 'en'">{{ showList.enContent }}</div>
              </div>
              <!-- 文本结束 -->
            </div>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <div class="advert1-item" v-if="planList && planList.length != 0">
              <!-- 标题开始 -->
              <div class="advert1-item-title">
                <div v-show="language == 'cn'">{{ planList.name }}</div>
                <div v-show="language == 'en'">{{ planList.enName }}</div>
                <div class="advert1-item-hr"></div>
              </div>
              <!-- 标题开始 -->
              <!-- 文本开始 -->
              <div class="advert1-item-msg">
                <div v-show="language == 'cn'">{{ planList.content }}</div>
                <div v-show="language == 'en'">{{ planList.enContent }}</div>
              </div>
              <!-- 文本结束 -->
            </div>
            <!-- 单一内容结束 -->
          </div>
          <!-- 内容结束 -->
          <!-- 电话开始 -->
          <div class="advert1-phone">
            <div class="advert1-phone-img">
              <img src="../assets/svg/server-advert-phone-icon.svg" alt="" />
            </div>
            <div class="advert1-phone-msg">
              {{ $t("message.cooperation") }}：
              <span>027-84696948</span>
            </div>
          </div>
          <!-- 电话结束 -->
          <!-- 图片开始 -->
          <div class="advert1-img">
            <img :src="advertList.img" alt="" />
          </div>
          <!-- 图片结束 -->
        </div>
      </div>
      <div class="hidden-sm-and-up">
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title">
            {{ $t("message.subNav3_3") }}
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <div
            class="smBox smBox-bg mt-20"
            v-if="designList && designList.length != 0"
          >
            <!-- 标题开始 -->
            <div class="advert1-item-title">
              <div v-show="language == 'cn'">{{ designList.name }}</div>
              <div v-show="language == 'en'">{{ designList.enName }}</div>
              <div class="advert1-item-hr"></div>
            </div>
            <!-- 标题开始 -->
            <!-- 文本开始 -->
            <div class="advert1-item-msg">
              <div v-show="language == 'cn'">{{ designList.content }}</div>
              <div v-show="language == 'en'">{{ designList.enContent }}</div>
            </div>
            <!-- 文本结束 -->
          </div>
          <div class="smBox smBox-bg" v-if="showList && showList.length != 0">
            <!-- 标题开始 -->
            <div class="advert1-item-title">
              <div v-show="language == 'cn'">{{ showList.name }}</div>
              <div v-show="language == 'en'">{{ showList.enName }}</div>
              <div class="advert1-item-hr"></div>
            </div>
            <!-- 标题开始 -->
            <!-- 文本开始 -->
            <div class="advert1-item-msg">
              <div v-show="language == 'cn'">{{ showList.content }}</div>
              <div v-show="language == 'en'">{{ showList.enContent }}</div>
            </div>
            <!-- 文本结束 -->
          </div>
          <div class="smBox smBox-bg" v-if="planList && planList.length != 0">
            <!-- 标题开始 -->
            <div class="advert1-item-title">
              <div v-show="language == 'cn'">{{ planList.name }}</div>
              <div v-show="language == 'en'">{{ planList.enName }}</div>
              <div class="advert1-item-hr"></div>
            </div>
            <!-- 标题开始 -->
            <!-- 文本开始 -->
            <div class="advert1-item-msg">
              <div v-show="language == 'cn'">{{ planList.content }}</div>
              <div v-show="language == 'en'">{{ planList.enContent }}</div>
            </div>
            <!-- 文本结束 -->
          </div>
          <!-- 电话开始 -->
          <div class="activity1-phone">
            <div class="activity1-phone-img">
              <img src="../assets/svg/server-phone-icon.svg" alt="" />
            </div>
            <div class="activity1-phone-label">
              {{ $t("message.cooperation") }}：
            </div>
            <div class="activity1-phone-msg">027-84696948</div>
          </div>
          <!-- 电话结束 -->
          <!-- 图片开始 -->
          <div class="smBox-img">
            <img :src="advertList.img" alt="" />
          </div>
          <!-- 图片结束 -->
          <div class="mt-20"></div>
        </div>
      </div>
      <!-- 广告服务第一部分结束 -->
    </div>
    <!-- 广告服务结束 -->
    <!-- 其他服务开始 -->
    <div class="other" v-if="tab == 4">
      <div class="container">
        <!-- 标题部分开始 -->
        <div class="title">
          其他服务
          <span class="small"></span>
          <span class="big"></span>
        </div>
        <!-- 标题部分结束 -->
        <!-- 第一部分开始 -->
        <div class="other1 hidden-xs-only">
          <!-- 图片内容部分开始 -->
          <div class="other1-content">
            <!-- 图片开始 -->
            <div class="other1-img-content">
              <img src="../assets/img/other-img1.png" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 文字开始 -->
            <div class="other1-msg-content">
              <!-- 标题开始 -->
              <div class="sub-title1">
                <span></span>
                现场服务
              </div>
              <!-- 标题结束 -->
              <!-- 单一内容开始 -->
              <div class="other1-item">
                <!-- 标题开始 -->
                <div class="other1-item-title">
                  <div class="other1-item-title-img">
                    <img src="../assets/svg/server-location-icon.svg" alt="" />
                  </div>
                  <div class="other1-item-title-msg">
                    现场服务点（客服中心）
                  </div>
                </div>
                <!-- 标题结束 -->
                <!-- 信息开始 -->
                <div class="other1-item-msg">位于展馆A\B登录厅</div>
                <!-- 信息结束 -->
              </div>
              <!-- 单一内容结束 -->
              <!-- 单一内容开始 -->
              <div class="other1-item">
                <!-- 标题开始 -->
                <div class="other1-item-title">
                  <div class="other1-item-title-img">
                    <img
                      src="../assets/svg/server-other-phone-icon.svg"
                      alt=""
                    />
                  </div>
                  <div class="other1-item-title-msg">客服电话</div>
                </div>
                <!-- 标题结束 -->
                <!-- 信息开始 -->
                <div class="other1-item-msg">027-84696372</div>
                <!-- 信息结束 -->
              </div>
              <!-- 单一内容结束 -->
            </div>
            <!-- 文字结束 -->
          </div>
          <!-- 图片内容部分结束 -->
        </div>
        <div class="smBox hidden-sm-and-up">
          <!-- 文字开始 -->
          <!-- 文字开始 -->
          <div class="other1-msg-content">
            <!-- 标题开始 -->
            <div class="sub-title1">
              <span></span>
              现场服务
            </div>
            <!-- 标题结束 -->
            <!-- 单一内容开始 -->
            <div class="other1-item">
              <!-- 标题开始 -->
              <div class="other1-item-title">
                <div class="other1-item-title-img">
                  <img src="../assets/svg/server-location-icon.svg" alt="" />
                </div>
                <div class="other1-item-title-msg">现场服务点（客服中心）</div>
              </div>
              <!-- 标题结束 -->
              <!-- 信息开始 -->
              <div class="other1-item-msg">位于展馆A\B登录厅</div>
              <!-- 信息结束 -->
            </div>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <div class="other1-item">
              <!-- 标题开始 -->
              <div class="other1-item-title">
                <div class="other1-item-title-img">
                  <img src="../assets/svg/server-other-phone-icon.svg" alt="" />
                </div>
                <div class="other1-item-title-msg">客服电话</div>
              </div>
              <!-- 标题结束 -->
              <!-- 信息开始 -->
              <div class="other1-item-msg">027-84696372</div>
              <!-- 信息结束 -->
            </div>
            <!-- 单一内容结束 -->
          </div>
          <!-- 文字结束 -->
          <!-- 文字结束 -->
          <!-- 图片开始 -->
          <div class="smBox-img mt-0">
            <img src="../assets/img/other-img1.png" alt="" />
          </div>
          <!-- 图片结束 -->
        </div>
        <!-- 第一部分结束 -->
        <!-- 数据部分开始 -->
        <div class="other2">
          <el-row :gutter="10">
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img1.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">先进的互联网及通讯服务</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img2.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">丰富多样的餐饮服务</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img3.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">24小时保安服务</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img4.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">周到全面的保洁服务</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img5.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">方便快捷的便利店</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img6.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">ATM自动取款机</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img7.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">馆内会议服务</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img8.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">展具物品租赁服务</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="6" :sm="6">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img9.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">商务服务（打印、传真、扫描）</div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <el-col :xs="8" :sm="18">
              <div class="other2-item">
                <!-- 图片开始 -->
                <div class="other2-item-img">
                  <img src="../assets/svg/other-icon-img10.svg" alt="" />
                </div>
                <!-- 图片结束 -->
                <!-- 文字开始 -->
                <div class="other2-item-msg">
                  完善的公用基础设施，水、电、气接驳服务
                </div>
                <!-- 文字结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
          </el-row>
        </div>
        <!-- 数据部分结束 -->
        <!-- 其他服务开始 -->
        <div class="other3">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            其他配套服务
          </div>
          <!-- 标题结束 -->
          <!-- 服务表格开始 -->
          <div class="service-table">
            <!-- 单一服务开始 -->
            <div class="service-table-item service-item-bg">
              <div class="service-table-item-label">展馆用电服务</div>
              <div class="service-table-item-content">
                单馆总供电量7200安，约3500千瓦，展位可提供的开关分别有：220伏*5安
                、 220伏*15安 、 220伏*20安 、 380伏*5安 、 380伏*15安 、
                380伏*20 安 、380伏*30安 、 380伏*60安
              </div>
            </div>
            <!-- 单一服务结束 -->
            <!-- 单一服务开始 -->
            <div class="service-table-item">
              <div class="service-table-item-label">展馆用水服务</div>
              <div class="service-table-item-content">
                市供水压力一般用水：管径Ø15mm
              </div>
            </div>
            <!-- 单一服务结束 -->
            <!-- 单一服务开始 -->
            <div class="service-table-item service-item-bg">
              <div class="service-table-item-label">电话、网络服务</div>
              <div class="service-table-item-content">
                电话：国际长途IDD 、 国内长途DDD 、 市话 网络：宽带（2M）
              </div>
            </div>
            <!-- 单一服务结束 -->
            <!-- 单一服务开始 -->
            <div class="service-table-item">
              <div class="service-table-item-label">商务服务</div>
              <div class="service-table-item-content">打印 、 复印 、 传真</div>
            </div>
            <!-- 单一服务结束 -->
            <!-- 单一服务开始 -->
            <div class="service-table-item service-item-bg">
              <div class="service-table-item-label">会议服务</div>
              <div class="service-table-item-content">
                会议室（120—160平米）、贵宾室（80—100平米）、
                新闻发布厅（120平米）、现场办公室
              </div>
            </div>
            <!-- 单一服务结束 -->
            <!-- 单一服务开始 -->
            <div class="service-table-item">
              <div class="service-table-item-label">物流服务</div>
              <div class="service-table-item-content">
                国内及国际运输段、仓储、包装、代购货运险
              </div>
            </div>
            <!-- 单一服务结束 -->
            <!-- 单一服务开始 -->
            <div class="service-table-item service-item-bg">
              <div class="service-table-item-label">劳务服务</div>
              <div class="service-table-item-content">
                电工 、 搭建工 、 保洁工 、 保安
              </div>
            </div>
            <!-- 单一服务结束 -->
            <!-- 单一服务开始 -->
            <div class="service-table-item">
              <div class="service-table-item-label">美工服务</div>
              <div class="service-table-item-content">
                制作标展楣板 、 不干胶刻字 、 KT板写真 、 写真
              </div>
            </div>
            <!-- 单一服务结束 -->
            <!-- 单一服务开始 -->
            <div class="service-table-item service-item-bg">
              <div class="service-table-item-label">植物租赁</div>
              <div class="service-table-item-content">
                绿植 、 花篮 、 胸花 、 花草
              </div>
            </div>
            <!-- 单一服务结束 -->
          </div>
          <!-- 服务表格结束 -->
        </div>
        <!-- 其他服务结束 -->
      </div>
    </div>
    <!-- 其他服务结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MyCarousel from "../components/MyCarousel.vue";
import MyCarousel2 from "../components/MyCarousel2.vue";
export default {
  components: { MyCarousel, MyCarousel2 },
  data() {
    return {
      tab: "", //1、展会/活动承接，2、展会自联办服务，3、广告服务，4、其他服务
      imgLists: [
        require("../assets/img/service-banner1.png"),
        require("../assets/img/service-banner2.png"),
        require("../assets/img/service-banner3.png"),
        require("../assets/img/service-banner4.png"),
        require("../assets/img/service-banner5.png"),
        require("../assets/img/service-banner6.png"),
        require("../assets/img/service-banner7.png"),
        require("../assets/img/service-banner8.png"),
        require("../assets/img/service-banner9.png"),
        require("../assets/img/service-banner10.png"),
      ],
      activityList: [], //展会/活动承接
      marketingList: [], //展会营销
      undertakeList: [], //展会承接
      exhibitionList: [], //展会自联办服务
      exhibitionLists: [], //自联办展品牌项目
      advertList: [], //广告服务
      designList: [], //广告设计发布
      showList: [], //展览展示工程
      planList: [], //会务策划、执行
      carouselIndex: 0, //走马灯初始index
    };
  },
  mounted() {
    let num = this.$route.query.tab;
    if (num) {
      this.tab = num;
    }
    //展会/活动承接 activityList
    this.$axios
      .get("api/matList?name=展会/活动承接&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          this.activityList = res.data.data[0];
        }
      });
    // 展会营销 marketingList
    this.$axios.get("api/matList?name=展会营销&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.marketingList = res.data.data[0];
      }
    });
    //展会承接  undertakeList
    this.$axios.get("api/matList?name=展会承接&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.undertakeList = res.data.data[0];
      }
    });
    //展会自联办服务 exhibitionList
    this.$axios
      .get("api/matList?name=展会自联办服务&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          this.exhibitionList = res.data.data[0];
        }
      });
    //自联办展品牌项目  exhibitionLists
    this.$axios
      .get("api/matList?name=自联办展品牌项目&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          this.exhibitionLists = res.data.data;
        }
      });
    //广告服务  advertList
    this.$axios.get("api/matList?name=广告服务&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.advertList = res.data.data[0];
      }
    });
    //广告设计发布  designList
    this.$axios.get("api/matList?name=广告设计发布&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.designList = res.data.data[0];
      }
    });
    //展览展示工程  showList
    this.$axios.get("api/matList?name=展览展示工程&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.showList = res.data.data[0];
      }
    });
    //会务策划、执行  planList
    this.$axios
      .get("api/matList?name=会务策划、执行&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          this.planList = res.data.data[0];
        }
      });
  },
  methods: {
    changeIndex(index) {
      this.carouselIndex = index;
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.mt-10 {
  margin-top: 10px;
}

.activity1-content {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.activity1-content + .activity1-content {
  margin-top: 80px;
}

.activity-img-content {
  width: 50%;
  display: flex;
}

.activity-img-content img {
  width: 100%;
}

.activity1-msg-content {
  width: 50%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 0 50px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.activity1-content-msg {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 34px;
}

.activity1-phone {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.activity1-phone-img {
  width: 24px;
  height: 24px;
  display: flex;
}

.activity1-phone-img img {
  width: 100%;
}

.activity1-phone-label {
  color: #004bc7;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 50px;
  margin: 0 5px 0 10px;
}

.activity1-phone-msg {
  color: #004bc7;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 32px;
}

@media (min-width: 768px) {
  .activity1-phone {
    margin-top: 90px;
    display: flex;
    align-items: center;
  }

  .activity1-phone-img {
    width: 24px;
    height: 24px;
    display: flex;
  }

  .activity1-phone-img img {
    width: 100%;
  }

  .activity1-phone-label {
    color: #004bc7;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 50px;
    margin: 0 5px 0 10px;
  }

  .activity1-phone-msg {
    color: #004bc7;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 32px;
  }
}

.activity2-content {
  display: flex;
  justify-content: space-between;
}

.activity2 {
  background: url("../assets/img/activity-bg-img1.png") no-repeat center;
  margin: 80px 0;
  padding: 80px 0;
}

.activity2-content-item {
  width: 580px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 20px;
}

.activity2-content-msg {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
}

.activity3 {
  margin: 20px 0;
}

.activity3-content {
  margin-bottom: 80px;
}

.exhibition1 {
  margin: 80px 0;
  display: flex;
}

.exhibition1-img-content {
  width: 50%;
}

.exhibition1-img-content img {
  width: 100%;
}

.exhibition1-msg-content {
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 0 50px;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.exhibition1-msg-content-msg {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 34px;
}

.exhibition1-phone {
  display: flex;
  align-items: center;
}

.exhibition1-phone-img {
  width: 24px;
  height: 24px;
  display: flex;
}

.exhibition1-phone-img img {
  width: 100%;
}

.exhibition1-phone-label {
  color: #004bc7;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 50px;
  margin: 0 5px 0 10px;
}

.exhibition1-phone-msg {
  color: #004bc7;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 32px;
}

.middle6 {
  margin: 80px 0;
  display: flex;
}

.middle6-right {
  width: 60%;
}

.middle6-right img {
  width: 100%;
}

.middle6-left {
  background-color: #083888;
  box-sizing: border-box;
  padding: 60px 20px 0 20px;
  flex: 1;
}

@media (min-width: 1200px) {
  .middle6-left {
    padding: 40px 80px 0 40px;
  }
}

@media (min-width: 1300px) {
  .middle6-right {
    width: 960px;
  }

  .middle6-left-phone {
    margin-top: 40px;
  }
}

.middle6-left .sub-title1 {
  color: #ffffff;
}

.middle6-left .sub-title1 span {
  background-color: #ffffff;
}

.middle6-left-msg1 {
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 46px;
}

.middle6-left-phone {
  color: #ffffff;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 50px;
  word-break: break-all;
}

.middle6-left-phone .labbel {
  font-size: 16px;
}

.exhibition2 {
  margin: 20px 0;
}

.exhibition2-content-msg {
  min-height: 78px;
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  margin: 20px 0 30px;
}

.el-carousel__item img {
  height: 100%;
}

@media (min-width: 768px) {
  .exhibition2-content-msg {
    min-height: 90px;
    color: #333333;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 26px;
    margin: 40px 0 80px;
  }
}

.advert1-content {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
}

.advert1-item {
  width: 373px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 20px;
}

.advert1-item:hover {
  background-color: #004bc7;
}

.advert1-item:hover .advert1-item-title,
.advert1-item:hover .advert1-item-msg {
  color: #ffffff;
}

.advert1-item:hover .advert1-item-hr {
  background-color: #ffffff;
}

.advert1-item-title {
  color: #333333;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
  padding-bottom: 8px;
  position: relative;
}

.advert1-item-hr {
  width: 20px;
  height: 3px;
  background-color: #004bc7;
  position: absolute;
  bottom: 0;
  left: 0;
}

.advert1-item-msg {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  margin-top: 16px;
}

.advert1-phone {
  margin: 40px 0;
  display: flex;
  align-items: center;
}

.advert1-phone-msg {
  color: #004bc7;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-left: 20px;
}

.advert1-phone-msg span {
  font-size: 24px;
  font-weight: bold;
}

.advert1-img {
  margin: 40px 0 80px;
  display: flex;
}

.advert1-img img {
  width: 100%;
}

@media (min-width: 768px) {
  .advert1-item-title {
    color: #333333;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 40px;
    padding-bottom: 24px;
    position: relative;
  }

  .advert1-item-hr {
    width: 34px;
    height: 4px;
    background-color: #004bc7;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .advert1-item-msg {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 30px;
    margin-top: 40px;
  }
}

.other1-content {
  display: flex;
  margin-top: 40px;
}

.other1-img-content {
  width: 912px;
  display: flex;
}

.other1-img-content img {
  width: 100%;
}

.other1-msg-content {
  background-color: #083888;
  box-sizing: border-box;
  padding: 30px 20px;
  flex: 1;
}

.other1-msg-content .sub-title1 {
  color: #ffffff;
}

.other1-msg-content .sub-title1 span {
  background-color: #ffffff;
}

.other1-item {
  margin-top: 20px;
}

.other1-item-title {
  display: flex;
}

.other1-item-title-img {
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 8px;
}

.other1-item-title-img img {
  width: 100%;
}

.other1-item-title-msg {
  color: #9cafcf;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.other1-item-msg {
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.other2 {
  margin: 20px 0;
}

.other2-item {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.other2-item-img {
  width: 32px;
  height: 32px;
  display: flex;
}

.other2-item-img img {
  width: 100%;
}

.other2-item-msg {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: 16px 0 30px;
}

.other3 {
  margin-bottom: 30px;
}

.service-table {
  border: 1px solid #dddddd;
}

.service-table-item {
  display: flex;
  align-items: center;
}

.service-item-bg {
  background-color: #f4f5fa;
}

.service-table-item-label {
  width: 120px;
  height: 120px;
  color: #0152d9;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  box-sizing: border-box;
  text-align: center;
  line-height: 120px;
}

.service-table-item-content {
  height: 120px;
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-left: 1px solid #dddddd;
  line-height: 19px;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.service-table-item {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .other1-msg-content {
    background-color: #083888;
    box-sizing: border-box;
    padding: 40px 30px 0;
    flex: 1;
  }

  .other1-msg-content .sub-title1 {
    color: #ffffff;
  }

  .other1-msg-content .sub-title1 span {
    background-color: #ffffff;
  }

  .other1-item {
    margin-top: 60px;
  }

  .other1-item-title {
    display: flex;
  }

  .other1-item-title-img {
    width: 20px;
    height: 20px;
    display: flex;
    margin-right: 8px;
  }

  .other1-item-title-img img {
    width: 100%;
  }

  .other1-item-title-msg {
    color: #9cafcf;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }

  .other1-item-msg {
    color: #ffffff;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 56px;
  }

  .other2 {
    margin: 40px 0 70px;
  }

  .other2-item {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }

  .other2-item-img {
    width: 32px;
    height: 32px;
    display: flex;
  }

  .other2-item-img img {
    width: 100%;
  }

  .other2-item-msg {
    color: #333333;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin: 16px 0 30px;
  }

  .other3 {
    margin-bottom: 80px;
  }

  .service-table {
    border: 1px solid #dddddd;
  }

  .service-table-item {
    display: flex;
    align-items: center;
  }

  .service-item-bg {
    background-color: #f4f5fa;
  }

  .service-table-item-label {
    width: 200px;
    height: 80px;
    color: #0152d9;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    box-sizing: border-box;
    text-align: center;
    line-height: 80px;
  }

  .service-table-item-content {
    height: 80px;
    color: #333333;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    border-left: 1px solid #dddddd;
    line-height: 19px;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 30px;
  }

  .service-table-item {
    display: flex;
    align-items: center;
  }
}
</style>
